import request from '../utils/request'

//查询新闻详情
export function getNewsInfo(data) {
  return request({
    url: '/news-config/selectOne',
    method: 'get',
    params: {
      id: data
    }
  })
}

//获取新闻推荐列表
export function getNewsList(data) {
  return request({
    url: '/news-config/selectNewsByType',
    method: 'get',
    params: data
  })
}
