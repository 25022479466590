import React, { Component } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import "./productInfo.scss";
import { getRequest } from "../../utils/index";

import { productInfoSelectOne } from "../../api/product";
class Index extends Component {
  content = React.createRef();
  state = {
    productTypeId:null
  };
  componentDidMount() {
    console.log(this.props);
    let params = getRequest(this.props.location.search);
    for (const key in params) {
      if (key == 'productId') {
        this.getList(params[key])
      }
      if(key == 'productTypeId'){
        this.setState({
          productTypeId:params[key]
        })
      }
    }
  }
  getList(id) {
    productInfoSelectOne(id).then(res => {
      if (res.status == "success") {
        this.content.current.innerHTML = res.data.content;
      } else {
        this.props.navigation("/product/center");
      }
    })
  }
  render() {
    return (
      <div className='product-info'>
        <div className="row-a1">
          <div className="title">
            <div className="tit">
              产品详情
            </div>
            <div className='txt'>
              PRODUCT DETAILS
            </div>
          </div>
          <div className="con wrap">
            <div
              className="btn"
              onClick={() => {
                this.props.navigation("/product/type?productTypeId="+this.state.productTypeId);
              }}
            >
              <div className="icon">
                <img
                  src={require("../../static/images/icon023.png")}
                  alt=""
                />
              </div>
              <div className="txt">返回</div>
            </div>
            <div className="content" ref={this.content}></div>
          </div>
        </div>
      </div>
    )
  }
}

export default function ProductInfo() {
  const navigation = useNavigate();
  const location = useLocation();
  return <Index navigation={navigation} location={location} />;
}