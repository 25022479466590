import React, { Component } from "react";
import "./area.scss";

import Swiper from "swiper/js/swiper.js"; // 引入js
import "swiper/css/swiper.min.css"; // 引入样式

export default class develop extends Component {
  state = {
    list: [
      {
        name: "电子烟花",
        img: require("../../static/images/pic044.png"),
        rightList: [
          {
            name: "工作原理",
            enName: "EXPAND DOMAIN",
            list: [
              {
                desc: "延时控制电路通常包括计时器、触发器、开关等组件，可在点火后一定时间内激活LED灯或其他效果装置。",
              },
              {
                desc: "通过精确控制延时时间，实现各种视觉和音效效果，增强用户体验和产品差异化。",
              },
            ],
          },
        ],
      },
    ],
  };
  componentDidMount() {}
  render() {
    return (
      <div className="area">
        <div className="row-a1">
          <div className="title">
            <div className="tit">拓展领域</div>
            <div className="txt">EXPAND DOMAIN</div>
          </div>
          <div className="wrap">
            {/* 电子烟花 */}
            <div className="item">
              <div className="left">
                <h5>电子烟花</h5>
                <img src={require("../../static/images/pic044.png")} alt="" />
              </div>
              <div className="right">
                <div className="li width23">
                  <h3>工作原理</h3>
                  <h6>working principle</h6>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      延时控制电路通常包括计时器、触发器、开关等组件，可在点火后一定时间内激活LED灯或其他效果装置。
                    </div>
                  </div>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      通过精确控制延时时间，实现各种视觉和音效效果，增强用户体验和产品差异化。
                    </div>
                  </div>
                </div>
                <div className="li width23">
                  <h3>优势</h3>
                  <h6>superiority</h6>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>

                    <div className="p">
                      提供丰富的烟花表现效果，如亮度变化、色彩转换
                      、闪烁模式等，让用户感受到更加生动的观赏体验。
                    </div>
                  </div>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      可根据不同需求调整延时时间，满足不同场景和设计要求，提高产品多样性和灵活性。
                    </div>
                  </div>
                </div>
                <div className="li width31">
                  <h3>趋势分析</h3>
                  <h6>trend analysis</h6>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      智能化与自定义化：未来电子烟花可能向智能化和
                      自定义化方向发展，用户可以通过APP或遥控器调整延时效果，创造个性化烟花表演。
                    </div>
                  </div>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      环保与可持续性：随着环保意识的增强，电子烟花将更多地替代传统烟花，延时控制电路也将更加注重低功耗、可充电等设计。
                      通过精确控制延时时间，实现各种视觉和音效效果，增强用户体验和产品差异化。
                    </div>
                  </div>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      安全考量：在延时控制电路设计中，安全性将是关键考虑因素，包括过载保护、过压保护等功能，以确保产品使用安全。
                    </div>
                  </div>
                </div>
                <div className="li width23">
                  <h3>市场应用</h3>
                  <h6>market application</h6>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      电子烟花已广泛应用于庆典活动、婚礼、舞台演出等场合，延时控制电路的应用使其更具魅力和观赏性。
                    </div>
                  </div>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      未来随着更多国家对传统烟花限制的加大，电子烟花市场有望迅速扩大，延时控制技术将得到更广泛的应用。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 灭火弹 */}
            <div className="item">
              <div className="left">
                <h5>灭火弹</h5>
                <img src={require("../../static/images/pic045.png")} alt="" />
              </div>
              <div className="right">
                <div className="li width23">
                  <h3>工作原理</h3>
                  <h6>working principle</h6>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      可以在点火后经过设定的时间延迟后触发灭火装置或动作，用于实现控制灭火时机和方式。
                    </div>
                  </div>
                </div>
                <div className="li width23">
                  <h3>优势</h3>
                  <h6>superiority</h6>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      精准控制：可以精确控制灭火动作的时间，确保在最佳时机进行灭火
                    </div>
                  </div>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      安全性：提供合适的延时控制可以使灭火行动更加有效和安全，同时通过物联网的控制，不需要人工涉入避免误操作或意外情况。
                    </div>
                  </div>
                </div>
                <div className="li width31">
                  <h3>趋势分析</h3>
                  <h6>trend analysis</h6>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      智能化：未来趋势可能会朝着智能化方向发展，延时控制模块可能与传感器技术结合，实现智能识别和自动触发
                    </div>
                  </div>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      节能环保：延时控制技术在灭火系统中的应用会注重能源效率和环保要求，以降低资源消耗和排放。
                    </div>
                  </div>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      数据驱动：结合数据分析和人工智能技术，延时控制模块能够根据实时数据调整延时时间，提高灭火效率。
                    </div>
                  </div>
                </div>
                <div className="li width23">
                  <h3>市场应用</h3>
                  <h6>market application</h6>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      在建筑、交通运输、工业等领域，灭火装置是至关重要的安全设备，延时控制模块的应用将进一步提升被火灾威胁环境的安全性。
                    </div>
                  </div>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      趋势将推动灭火系统的进一步自动化和智能化，提高灭火效率和减少人为干预的依赖
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 降雨弹 */}
            <div className="item">
              <div className="left">
                <h5>降雨弹</h5>
                <img src={require("../../static/images/pic046.png")} alt="" />
              </div>
              <div className="right">
                <div className="li width23">
                  <h3>工作原理</h3>
                  <h6>working principle</h6>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      可以在点火后经过设定的时间延迟后触发降雨弹装置或动作，用于实现控制降雨时机和方式。
                    </div>
                  </div>
                </div>
                <div className="li width23">
                  <h3>优势</h3>
                  <h6>superiority</h6>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      定制化控制：延时控制模块可以根据需求进行定制，为特定任务提供精确的时间控制。
                    </div>
                  </div>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      节能环保：通过有效的延时控制，可以减少能耗，提高资源利用效率。
                    </div>
                  </div>
                </div>
                <div className="li width31">
                  <h3>趋势分析</h3>
                  <h6>trend analysis</h6>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      智能化发展：随着物联网技术的发展，延时控制模块可能与智能设备进行无线连接，实现远程控制和自动化操作。
                    </div>
                  </div>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      节能高效：在环保意识不断增强的背景下，延时控制模块的设计将更多关注节能降耗，推动绿色发展。
                    </div>
                  </div>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      数据驱动：未来延时控制技术可能结合数据分析和人工智能，实现更智能的控制策略，提高系统的智能性和效率。
                    </div>
                  </div>
                </div>
                <div className="li width23">
                  <h3>市场应用</h3>
                  <h6>market application</h6>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      应用领域涵盖农业灌溉系统、天气模拟装置、工业生产过程等需要时间控制的领域。
                    </div>
                  </div>
                  <div className="desc">
                    <img
                      className="icon"
                      src={require("../../static/images/icon036.png")}
                    ></img>
                    <div className="p">
                      随着对智能、高效、节能的需求增加，延时控制模块在自动化设备等领域的应用有望持续扩大。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
