import React, { Component } from 'react';
import { message } from 'antd';

import "./illustrate.scss";
export default class extends Component {

  componentDidMount() {

  }
  copy() {
    navigator.clipboard.writeText("kunchengdianzi@shkcdz.com");
    message.success('复制成功!');
  }
  render() {
    return (
      <div className='illustrate'>
        <div className="row-a1">
          <div className="title">
            <div className="tit">
              产品使用说明
            </div>
            <div className="txt">
              PRODUCT INSTRUCTIONS
            </div>
          </div>
          <div className="con wrap">
            <div className="list">
              <div className="li">
                <div className="tit">
                  产品说明书
                </div>
                <a className="btn" href="/download/use">
                  进入
                </a>
              </div>
              <div className="li">
                <div className="tit">
                  起爆控制器操作指引视频
                </div>
                <a className="btn" href="/download/video">
                  进入
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
