import React, { Component } from 'react'
import { Button, Form, Input } from 'antd';


import "./custom.scss";

export default class custom extends Component {

  componentDidMount() {

  }
  validatePhone = (_, value) => {
    // 采用正则表达式进行校验
    const phoneRegex = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    // 判断 手机号 是否符合 phoneRegex 正则的校验
    if (value && !phoneRegex.test(value)) {
      // 不符合就返回错误
      return Promise.reject('手机号格式错误');
    } else {
      // 符合就返回成功
      return Promise.resolve();
    }
  };
  onFinish = (e) => {
    console.log("onFinish");
    console.log(e);
  };
  render() {
    const { TextArea } = Input;
    return (
      <div className='custom'>
        <div className="row-a1">
          <div className="title">
            <div className="tit">
              客户反馈
            </div>
            <div className="txt">
              CULTIVATION DEVELOPMENT
            </div>
          </div>
          <div className="con wrap">
            <div className="tit">
              您对本公司的产品有什么宝贵建议，或者有需要我们改进和优化的地方，请告诉我们
            </div>
            <Form
              name="basic"
              labelAlign="left"
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 18,
              }}
              style={{
                maxWidth: 450
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={this.onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="联系人"
                name="username"
                rules={[
                  {
                    required: true,
                    message: '联系人',
                  },
                ]}
              >
                <Input placeholder="请输入联系人" />
              </Form.Item>
              <Form.Item
                label="联系电话"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: '联系电话',
                  },
                  { validator: this.validatePhone }
                ]}
              >
                <Input placeholder="请输入联系电话" />
              </Form.Item>
              <Form.Item label="详细内容"
                name="mark"
                rules={[
                  {
                    required: true,
                    message: '详细内容',
                  },
                ]} >
                <TextArea rows={3} placeholder="请输入详细内容" autoSize={false} />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 6,
                  span: 18,
                }}
                className="submit"
              >
                <Button type="primary" htmlType="submit">
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}
