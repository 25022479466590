import React, { Component } from 'react';
import "./develop.scss"
export default class develop extends Component {
  render() {
    return (
      <div className='develop'>
        <div className="row-a1">
          <div className="title">
            <div className="tit">
              可持续发展
            </div>
            <div className='txt'>
              SUSTAINABLE DEVELOPMENT
            </div>
          </div>
          <div className="con wrap">
            <div className="list">
              <div className="li">
                <div className="pic">
                  <img src={require("../../static/images/pic067.png")} alt="" />
                </div>
                <div className="text">
                  <div className="desc">
                    鲲程电子在企业发展的同时十分重视企业的社会责任，大力推进节能减排绿色环保工作。不仅在产生废气的工序积极投放环保处理设施，使处理后的气体合规达标排放，并通过工艺的创新、优化与设备的升级改造，提高生产效率减少产废工序。在原材料方面，通过引进环保型材料，使产品从原材料到生产工序所用的辅料达到无铅化生产。
                    公司一直厉行节能降耗，倡导办公人员减少一次性用品消耗，减少待机能耗，开展关闭公共照明区域照明等体验活动，鼓励员工上下班优先选乘公共交通、骑自行车和步行上下班，以低碳的办公模式和出行方式体验能源紧缺，支持节能减排
                  </div>
                </div>
              </div>
              <div className="li">
                <div className="pic">
                  <img src={require("../../static/images/pic068.png")} alt="" />
                </div>
                <div className="text">
                  <div className="desc">
                    鲲程电子一直秉承“让民爆产品用上中国芯片”作为奋斗目标。2018年，国家提出要在5年内实现数码电子雷管全面代替传统产品，这对鲲程来说是个难得的机遇。” 同年4月，德州鲲程电子科技有限公司成立，并斥资在天衢新区德州中元科创园建立生产基地，主营民爆物品智能监控管理领域产品的研发、生产和销售，核心产品包括电子雷管延时控制电路模组、数码雷管专用起爆系统、民爆物品安全监管平台等。
                    做优民爆产品要掌握两个核心关键词：安全和智慧。安全主要体现在产品品质上，智慧则彰显于产品性能中。以电子雷管延时控制电路模组为例，一板模组是10发，每发都有一个芯片，尺寸仅为0.035平方毫米，嵌入独立代码后，就如同为产品打上了身份证号，具有了唯一性。 将单个芯片装入雷展
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
