
import axios from 'axios'
import { tansParams } from "./index";
import { message } from 'antd';

const service = axios.create({
  baseURL: '/api',//基准地址
  timeout: 60000
})
//拦截请求
service.interceptors.request.use((config) => {
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  };
  return config
})
//拦截响应
service.interceptors.response.use((res) => {
  const { status } = res.data;
  if (status == 'success') {
    return res.data;
  } else {
    message.info({
      content: res.msg,
    })
    return res.data;
  }
}, function (error) {
  //对响应的错误做点什么
  return Promise.reject(error);
})

export default service;