import React, { Component } from 'react';
import "./invest.scss"
export default class develop extends Component {
  render() {
    return (
      <div className='invest'>
        <div className="row-a1">
          <div className="title">
            <div className="tit">
              投资者关系
            </div>
            <div className='txt'>
              INVESTOR RELATIONS
            </div>
          </div>
          <div className="con wrap">
            
          </div>
        </div>
      </div>
    )
  }
}
