import React, { Component } from "react";
import "./cooperate.scss";

import { getWebsiteInfo } from "../../api/common.js";

export default class Cooperate extends Component {
  componentDidMount() {
    this.getList();
  }
  state = {
    line1: [],
    line2: [],
  };
  getList() {
    getWebsiteInfo(4).then((res) => {
      let line1 = [];
      let line2 = [];
      for (let i = 0; i < 13; i++) {
        if (i < 7) {
          line1.push(
            Object.assign(
              {
                image: "../../static/images/pic012.png",
                title: "",
              },
              res.data[i]
            )
          );
        } else {
          line2.push(
            Object.assign(
              {
                image: "../../static/images/pic012.png",
                title: "",
              },
              res.data[i]
            )
          );
        }
      }
      console.log(line1);
      console.log(line2);
      this.setState({
        line1,
        line2,
      });
    });
  }
  render() {
    const { line1, line2 } = this.state;
    return (
      <div className="cooperate">
        <div className="row-a1">
          <div className="title">
            <div className="tit">战略合作</div>
            <div className="txt">STRATEGIC COOPERATION</div>
          </div>
          <div className="con wrap">
            <div className="con-a1">
              {line1.map((item, index) => {
                return (
                  <div className="li" key={index}>
                    <div className="back">
                      <img
                        src={require("../../static/images/pic010.png")}
                        alt=""
                      />
                    </div>
                    <div className="item">
                      <div className="icon">
                        <img src={item.image[0] ? item.image[0] : ""} alt="" />
                      </div>
                      <div className="txt">{item.title ? item.title : ""}</div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="con-a2">
              {line2.map((item, index) => {
                return (
                  <div className={item.title ? "li" : "li opacity"} key={index}>
                    <div className="back">
                      <img
                        src={require("../../static/images/pic010.png")}
                        alt=""
                      />
                    </div>
                    <div className="item">
                      <div className="icon">
                        <img src={item.image[0] ? item.image[0] : ""} alt="" />
                      </div>
                      <div className="txt">{item.title ? item.title : ""}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="desc">以上排序不分先后</div>
        </div>
      </div>
    );
  }
}
