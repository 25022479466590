import React, { Component } from 'react'
import "./build.scss"

import OrgChart, { OrgChartNodeDataType } from '@twp0217/react-org-chart';

import { aboutBuild } from "../../api/about";
export default class Build extends Component {
  componentDidMount() {
    this.getTree();
  }
  state = {
    current: 0,
    tree: {

    }
  }
  getTree(current = 1) {
    let param = {
      key: 0,
      label: "上海鲲程电子科技有限公司",
      children: []
    }
    aboutBuild({ type: current }).then(res => {
      param.children = res.data;
      this.setState({
        tree: param,
        current
      })
    })
  }
  render() {
    const { current, tree } = this.state;
    return (
      <div className='build'>
        <div className="row-a1">
          <div className="title">
            <div className="tit">
              企业架构
            </div>
            <div className="txt">
              ORGANIZATIONAL STRUCTURE
            </div>
          </div>
          <div className="con wrap">
            <div className="navs">
              <div className={current == 1 ? 'current nav' : 'nav'} onClick={() => { this.getTree(1) }}>
                上海鲲程
              </div>
              <div className={current == 0 ? 'current nav' : 'nav'} onClick={() => { this.getTree(0) }}>
                关联企业
              </div>
            </div>
            <div className="tree">
              <OrgChart data={tree} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
