import React, { Component } from "react";
import { getWebsiteInfo } from "../../api/common.js";

import "./regard.scss";

export default class extends Component {
  state = {
    list: [],
  };
  componentDidMount() {
    this.getList();
  }
  getList() {
    getWebsiteInfo(2).then((res) => {
      this.setState({
        list: res.data,
      });
    });
  }
  render() {
    const { list } = this.state;
    return (
      <div className="regard">
        <div className="row-a1">
          <div className="title">
            <div className="tit">培养发展</div>
            <div className="txt">CULTIVATION DEVELOPMENT</div>
          </div>
          <div className="con">
            <div className="banner">
              <img src={require("../../static/images/pic079.png")} alt=""></img>
              <div className="main-desc">
                <h3>提升职业技能</h3>
                <h3>探索更多可能</h3>
                <h6 className="marTop10">
                  鲲程电子科技有限公司根据人才属性，设立了不同
                  的人才职业发展规划，
                </h6>
                <h6>
                  做到识人、用人、育人、留人，使能者上、平者让、庸者下的良好人才竞争优势。
                </h6>
                <div
                  className="link"
                  onClick={() => {
                    window.scrollTo({
                      top: document.body.scrollHeight,
                      behavior: "smooth",
                    });
                  }}
                >
                  了解体系 >
                </div>
              </div>
            </div>
            <div className="info">
              <h2>鲲程电子科技有限公司网络学院开学啦！</h2>
              <p>
                据企业员工不同的岗位设定了专属的培训课程。明确了量化的培训目标，培养周期，培养方式，各项考核与测试，奖惩规定以及管理办法；
              </p>
              <p>
                旨在从专业能力和管理领导力两个方向对公司员工进行培养，对提升公司整体管理运营能力、构建学习型企业是一项重要的方式。
              </p>
              <div className="course">
                <div className="head">
                  <h3>课程</h3>
                  <h6>全部 ></h6>
                </div>
                <div className="foot">
                  <div className="item">
                    <img
                      src={require("../../static/images/pic074.png")}
                      alt=""
                    ></img>
                    <h3>7招搞定线上招聘</h3>
                  </div>
                  <div className="item">
                    <img
                      src={require("../../static/images/pic075.png")}
                      alt=""
                    ></img>
                    <h3>演讲制胜—做精彩的商务演讲与...</h3>
                  </div>
                  <div className="item">
                    <img
                      src={require("../../static/images/pic076.png")}
                      alt=""
                    ></img>
                    <h3>华为营销：道局术</h3>
                  </div>
                  <div className="item">
                    <img
                      src={require("../../static/images/pic077.png")}
                      alt=""
                    ></img>
                    <h3>180分钟摆脱菜鸟销售</h3>
                  </div>
                </div>
              </div>
              <div className="tip">在线登陆方式：</div>
              <p>
                员工可通过后机或电脑登录个人的钉钉账户，在工作台中找到“酷学院”即可看到综合管理部根据每个人的岗位不同导入的培训课程。
              </p>
              <p>
                每次培训课程结束后会有相应的模块测试，用来巩固及检验学习的情况。
              </p>
            </div>
            <div className="solt">
              <img src={require("../../static/images/pic078.png")} alt=""></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
