import React, { Component } from 'react'
import "./error.scss"
export default class Error extends Component {
  render() {
    return (
      <div className='error'>
        <div className='title'>
          <div className="tit">
            error
          </div>
          <div className="txt">
            未找到页面
          </div>
        </div>
      </div>
    )
  }
}
