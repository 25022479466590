import React, { Component } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import "./company.scss";

import { getRequest, formatDateStr } from "../../utils/index";

import { getNewsInfo, getNewsList } from "../../api/news.js";

class Index extends Component {
  html = React.createRef();
  state = {
    news: {

    },
    industryList: [],
    companyList: []
  }
  componentDidMount() {
    this.getList();
  }
  getList() {
    let params = getRequest(this.props.location.search);
    let status = true;
    let that = this;
    for (const key in params) {
      if (key == 'id') {
        status = false;
        getNewsInfo(params[key]).then(res => {
          that.html.current.innerHTML = res.data.newsContent;
          that.setState({
            news: res.data
          })
        })
      }
    }
    getNewsList({
      newsType: 0,
      isShow: 0
    }).then(res => {
      if (status && res.data.length > 0) {
        getNewsInfo(res.data[0].id).then(res => {
          that.html.current.innerHTML = res.data.newsContent;
          that.setState({
            news: res.data
          })
        })
      }
      that.setState({
        industryList: res.data
      })
    })
    getNewsList({
      newsType: 1
    }).then(res => {
      that.setState({
        companyList: res.data
      })
    })
  }
  render() {
    const { news, industryList, companyList } = this.state;
    return (
      <div className='company'>
        <div className="row-a1">
          <div className="title">
            <div className="tit">
              企业动态
            </div>
            <div className="txt">
              ENTERPRISE UPDATES
            </div>
          </div>
          <div className="con wrap">
            <div className="content">
              <div className="tit">
                {news.newsTitle}
              </div>
              <div className="date">
                {formatDateStr(news.updateTime)}
              </div>
              <div className="html" ref={this.html}>

              </div>
            </div>
            <div className="news">
              <div className="new">
                <div className="tit">
                  更多企业动态
                </div>
                <div className="list">
                  {
                    companyList.map((item, index) => {
                      return (
                        <div className="li" key={index}>
                          <div className="pic">
                            <img src={item.newsImage} alt="" />
                          </div>
                          <div className="text">
                            <div className="tit ellipsis">
                              {item.newsTitle}
                            </div>
                            <div className="desc">
                              <div className="txt ellipsis_3">
                                {item.description}
                              </div>
                              <a href={"/news/company?id=" + item.id}>了解更多</a>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>

              <div className="new">
                <div className="tit">
                  更多行业动态
                </div>
                <div className="list">
                  {
                    industryList.map((item, index) => {
                      return (
                        <div className="li" key={index}>
                          <div className="pic">
                            <img src={item.newsImage} alt="" />
                          </div>
                          <div className="text">
                            <div className="tit ellipsis">
                              {item.newsTitle}
                            </div>
                            <div className="desc">
                              <div className="txt ellipsis_3">
                                {item.description}
                              </div>
                              <a href={"/news/industry?id=" + item.id}>了解更多</a>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>


        </div>






      </div>
    )
  }
}

export default function Company() {
  const navigation = useNavigate();
  const location = useLocation();
  return <Index navigation={navigation} location={location} />;
}