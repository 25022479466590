import React, { Component } from "react";
import "./history.scss";
export default class develop extends Component {
  render() {
    return (
      <div className="history">
        <div className="row-a1">
          <div className="title">
            <div className="tit">企业发展历程</div>
            <div className="txt">INVESTOR RELATIONS</div>
          </div>
          <div className="con wrap">
            <div className="list">
              <div className="li">
                <div className="name">上海鲲程电子科技有限公司成立</div>
                <div className="year">
                  <div className="text">2014年</div>
                  <div className="icon">
                    <img
                      src={require("../../static/images/icon034.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="pic">
                  <img src={require("../../static/images/pic069.png")} alt="" />
                </div>
                <div className="desc">
                  这一年公司创始人在上海自贸区注册成立了上海鲲程掀开了鲲程电子发展的篇章
                </div>
              </div>
              <div className="li">
                <div className="name">德州鲲程电子科技有限公司成立</div>
                <div className="year">
                  <div className="text">2018年</div>
                  <div className="icon">
                    <img
                      src={require("../../static/images/icon034.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="pic">
                  <img src={require("../../static/images/pic070.png")} alt="" />
                </div>
                <div className="desc">
                  这一年公司开起战略化投资，成立德州鲲程，将上海鲲程的研发成果转化落地成产品，开始了研、产、销一体化，为进一步开拓市场奠定基础
                </div>
              </div>
              <div className="li">
                <div className="name">山东伽傲科技发展公司有限公司成立</div>
                <div className="year">
                  <div className="text">2020年</div>
                  <div className="icon">
                    <img
                      src={require("../../static/images/icon034.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="pic">
                  <img src={require("../../static/images/pic071.png")} alt="" />
                </div>
                <div className="desc">
                  与德州高创合资成立山东伽傲科技，完善了产品前工序生产，为生产提供了有力保证
                </div>
              </div>
              <div className="li">
                <div className="name">江西国鲲微电子科技有限公司成立</div>
                <div className="year">
                  <div className="text">2021年</div>
                  <div className="icon">
                    <img
                      src={require("../../static/images/icon034.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="pic">
                  <img src={require("../../static/images/pic072.png")} alt="" />
                </div>
                <div className="desc">
                  与江西国泰合资成立江西国鲲微电子科技公司，强强联合，稳定了市场。携手汉虎资本完成企业A轮融资，为企业后续扩张打下坚实基础
                </div>
              </div>
              <div className="li">
                <div className="name">
                  泰州鲲程电子科技有限公司成立
                  收购无锡矽励微电子有限公司股份成为大股东
                </div>
                <div className="year">
                  <div className="text">2022年</div>
                  <div className="icon">
                    <img
                      src={require("../../static/images/icon034.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="pic">
                  <img src={require("../../static/images/pic073.png")} alt="" />
                </div>
                <div className="desc">
                  利用长三角行业优势，成立泰州鲲程电子，并收购无锡矽励微电子有限公司股份，公司生产工序向下游延伸，稳定了材料供应
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
