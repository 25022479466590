import React, { Component } from 'react';
import "./case.scss";

import Swiper from 'swiper/js/swiper.js'; // 引入js
import 'swiper/css/swiper.min.css'; // 引入样式

export default class develop extends Component {
  state = {
    mySwiperRowa1: null
  }
  componentDidMount() {
    const mySwiperRowa1 = new Swiper('.swiper-container-row-a1', {
      slidesPerView: 4,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination-row-a1',
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        500: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        769: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 30,
        }
      }
    });
    this.setState({
      mySwiperRowa1
    })
  }
  render() {
    return (
      <div className='case'>
        <div className="row-a1">
          <div className="title">
            <div className="tit">
              经典案例
            </div>
            <div className='txt'>
              INVESTOR RELATIONS
            </div>
          </div>
          <div className="con wrap">
            <div className='swiper-container-row-a1'>
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="item">
                    <div className="pic">
                      <img src={require("../../static/images/pic039.png")} alt="" />
                    </div>
                    <div className="text">
                      湖北省某地露天爆破，一次爆破电子雷管用量1200余发，炸药用量1500吨，爆破立方约为200万立方，采用无线起爆方式，无线起爆距离高达2500米。
                    </div>
                    <div className="shade">
                      <div className="txt">
                        露天爆破
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="item">
                    <div className="pic">
                      <img src={require("../../static/images/pic040.png")} alt="" />
                    </div>
                    <div className="text">
                      山东省某地井下爆破，一次爆破电子雷管用量3000余发，炸药用量3000吨，使用煤矿许用起爆器。
                    </div>
                    <div className="shade">
                      <div className="txt">
                        井下爆破
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="item">
                    <div className="pic">
                      <img src={require("../../static/images/pic041.png")} alt="" />
                    </div>
                    <div className="text">
                      广东省某地水下爆破，一次爆破电子雷管用量1000余发，炸药用量1500吨，采用抛掷起爆方式
                    </div>
                    <div className="shade">
                      <div className="txt">
                        水下爆破
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="item">
                    <div className="pic">
                      <img src={require("../../static/images/pic042.png")} alt="" />
                    </div>
                    <div className="text">
                      湖北省某地某大桥拆除项目，应用市区复杂环境，一次爆破电子雷管用量3000余发，炸药用量4000吨
                    </div>
                    <div className="shade">
                      <div className="txt">
                        爆破拆除
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="item">
                    <div className="pic">
                      <img src={require("../../static/images/pic043.png")} alt="" />
                    </div>
                    <div className="text ellipsis_5">
                      湖北省某地隧道爆破，一次爆破电子雷管用量1200余发，炸药用量1500吨，隧道长度约2000米，采用无线起爆方式，无线起爆距离高达2500米。
                    </div>
                    <div className="shade">
                      <div className="txt">
                        隧道爆破
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination-row-a1">
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
