import React, { Component } from 'react'
import { useNavigate } from "react-router-dom";
import "./index.scss"

import Swiper from 'swiper/js/swiper.js' // 引入js
import 'swiper/css/swiper.min.css' // 引入样式

import { getNewsList } from "../../api/news";

import { formatDateStr } from "../../utils/index"

class Index extends Component {
  state = {
    mySwiperBanner: null,
    mySwiperRowa2: null,
    mySwiperRowa3: null,
    swiperIndex: 0,
    industryList: []
  }
  componentDidMount() {
    const that = this;

    this.getList();

    const mySwiperBanner = new Swiper('.swiper-container-banner', {
      effect: 'fade',
      autoplay: {
        delay: 5000
      },
      on: {
        slideChangeTransitionStart: function () {
          that.setState({
            swiperIndex: this.activeIndex
          })
        },
      },
    })

    const mySwiperRowa3 = new Swiper('.swiper-container-row-a3', {
      slidesPerView: 3,
      spaceBetween: 30,
      centeredSlides: true, // 活动块居中
      loop: true,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '.swiper-pagination-row-a3',
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        500: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        769: {
          slidesPerView: 3,
          spaceBetween: 30,
        }
      }
    })

    mySwiperRowa3.el.onmouseover = function () {
      mySwiperRowa3.autoplay.stop();
    }
    mySwiperRowa3.el.onmouseout = function () {
      mySwiperRowa3.autoplay.start();
    }

    this.setState({
      mySwiperBanner,
      mySwiperRowa3
    })
  }
  getList() {
    return new Promise((resolve, reject) => {
      getNewsList({
        newsType: 0,
        isShow: 0
      }).then(res => {
        this.setState({
          industryList: res.data
        })
        setTimeout(() => {
          const mySwiperRowa2 = new Swiper('.swiper-container-row-a2', {
            slidesPerView: 4,
            spaceBetween: 30,
            loop: true,
            navigation: {
              nextEl: ".swiper-button-next-row-a2",
              prevEl: ".swiper-button-prev-row-a2",
            },
            breakpoints: {
              0: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              500: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              769: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }
          })
          this.setState({
            mySwiperRowa2
          })
        }, 200);
      })
    })

  }
  goPage(path) {
    this.props.navigation(path);
  }
  render() {
    const { swiperIndex, industryList } = this.state;
    return (
      <div className='home'>
        <div className='banner'>
          <div className='swiper-container-banner'>
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className='pic'>
                  <img src={require("../../static/images/banner001.png")} alt="" />
                </div>

                <div className="con wrap">
                  <div className="box">
                    <div className="title">
                      <div className={swiperIndex == 0 ? 'tit  animate__animated animate__zoomIn' : 'tit'}>
                        愿景
                      </div>
                      <div className={swiperIndex == 0 ? 'txt  animate__animated animate__zoomIn' : 'txt'}>
                        Aspiration
                      </div>
                    </div>
                    <div className={swiperIndex == 0 ? 'desc animate__animated animate__fadeInUp' : 'desc'}>
                      做世界一流的电子雷管<br />
                      核心技术和产品<br />
                      供应商
                    </div>
                  </div>
                </div>

              </div>
              <div className="swiper-slide">
                <div className='pic'>
                  <img src={require("../../static/images/banner002.png")} alt="" />
                </div>


                <div className="con wrap">
                  <div className="box">
                    <div className="title">
                      <div className={swiperIndex == 1 ? 'tit  animate__animated animate__zoomIn' : 'tit'}>
                        使命
                      </div>
                      <div className={swiperIndex == 1 ? 'txt  animate__animated animate__zoomIn' : 'txt'}>
                        Minssion
                      </div>
                    </div>
                    <div className={swiperIndex == 1 ? 'desc animate__animated animate__fadeInUp' : 'desc'}>
                      让民爆<br />
                      更安全<br />
                      更高效
                    </div>
                  </div>
                </div>

              </div>
              <div className="swiper-slide">
                <div className='pic'>
                  <img src={require("../../static/images/banner003.png")} alt="" />
                </div>
                <div className="con wrap">
                  <div className="box">
                    <div className="title">
                      <div className={swiperIndex == 2 ? 'tit  animate__animated animate__zoomIn' : 'tit'}>
                        经营理念
                      </div>
                      <div className={swiperIndex == 2 ? 'txt  animate__animated animate__zoomIn' : 'txt'}>
                        Operation Principle
                      </div>
                    </div>
                    <div className={swiperIndex == 2 ? 'desc animate__animated animate__fadeInUp' : 'desc'}>
                      深入融合，合作共赢<br />
                      以人为本，务实创新
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="row-a1">
          <div className="con wrap">
            <div className="left">
              <img src={require("../../static/images/pic001.png")} alt="" />
            </div>
            <div className="right">
              <div className="tit">
                企业简介
              </div>
              <div className="desc">
                上海鲲程电子科技有限公司成立于2014年，是一家专业从事工业电子雷
                管专用延期模块核心技术研发和配套产品供应的高科技型企业。公司拥有完
                整的工业电子雷管专用延期模块核心技术 解决方案、全系列专用芯片和关键
                设备等，主要产品为“信安” 电子雷管专用芯片、控制系统、生产检测设
                备、安全监控平台 等。技术研发涵盖微电子、通信、仪器仪表、物联网等多
                个领域，拥有多项自主知识产权的专利技术。
              </div>
              <div className="btn">
                <a href="#">点击了解更多</a>
              </div>
            </div>
          </div>
        </div>
        <div className="row-a2">
          <div className="title">
            <div className="tit">
              行业动态
            </div>
            <div className="txt">
              INDUSTRY TRENDS
            </div>
          </div>
          <div className="con wrap">
            <div className='swiper-container-row-a2'>
              <div className="swiper-wrapper">
                {
                  industryList.map((item, index) => {
                    return (
                      <div className="swiper-slide" key={index}>
                        <div className="item">
                          <div className="pic">
                            <img src={item.newsImage} alt="" />
                          </div>
                          <div className="text">
                            <div className="date">
                              {formatDateStr(item.updateTime)}
                            </div>
                            <div className="desc">
                              <div className='ellipsis_3'>
                                {item.newsTitle}
                              </div>
                            </div>
                            <div className="btn" onClick={() => { this.goPage('/news/industry?id=' + item.id) }}>
                              立即了解
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="swiper-button-prev-row-a2">
              <img src={require("../../static/images/icon001.png")} alt="" />
            </div>
            <div className="swiper-button-next-row-a2">
              <img src={require("../../static/images/icon001.png")} alt="" />
            </div>
          </div>
        </div>
        <div className="row-a3">
          <div className="title">
            <div className="tit">
              企业优势
            </div>
            <div className="txt">
              ENTERPRISE ADVANTAGE
            </div>
          </div>
          <div className="con wrap">
            <div className="page">
              <div className="swiper-pagination-row-a3">
              </div>
            </div>
            <div className='swiper-container-row-a3'>
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="item">
                    <div className="pic">
                      <img src={require("../../static/images/pic006.png")} alt="" />
                    </div>
                    <div className="text">
                      <div className="tit">
                        研发实力
                      </div>
                      <div className="desc ellipsis_3">
                        公司以国际化的视野、前瞻性的眼光、高起点的规划，打造了一支实力雄厚、勇于实践、不断创新的研发团队，凝聚了一批行业研发专家型人才，海外留学的高科技博士后、博士、硕士人才和经验丰富的高级工程师技术服务团队。
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="item">
                    <div className="pic">
                      <img src={require("../../static/images/pic007.png")} alt="" />
                    </div>
                    <div className="text">
                      <div className="tit">
                        技术服务
                      </div>
                      <div className="desc ellipsis_3">
                        团队能够从多个方面汇集各类专业知识和技能。这种多样化的团队构成有助于推动电子雷管民爆行业的技术创新和发展，为行业提供更加优质和有效的解决方案。
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="item">
                    <div className="pic">
                      <img src={require("../../static/images/pic008.png")} alt="" />
                    </div>
                    <div className="text">
                      <div className="tit">
                        生产及供应
                      </div>
                      <div className="desc ellipsis_3">
                        我司引入了定制的自动化生产线，该生产线根据我司产品的特点进行了精心设计，能够大幅提升生产效率，降低成本，保证产品质量的稳定性和一致性，同时还可根据不同型号产品进行不断的优化及升级改造
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="item">
                    <div className="pic">
                      <img src={require("../../static/images/pic009.png")} alt="" />
                    </div>
                    <div className="text">
                      <div className="tit">
                        质量管理
                      </div>
                      <div className="desc ellipsis_3">
                        严格规定原料成品外观、尺寸、电性能、工作条件、技术要求、使用环境、储存条件、保质期、运输及包装等各方面，保证原材料质量。
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default function Home() {
  const navigation = useNavigate();
  return <Index navigation={navigation} />;
}