import request from '../utils/request';

//查看产品详情
export function productInfoSelectOne(data) {
  return request({
    url: '/product-detail/selectDetailById?productId=' + data,
    method: 'get'
  })
}

//查询所有产品分类列表
export function getProductTypeList() {
  return request({
    url: '/product-type/selectByCondition',
    method: 'get',
    params: {
      currentPage: 1,
      size: 100
    }
  })
}

//查看产品分类详情
export function productTypeSelectOne(data) {
  return request({
    url: '/product-type/selectOne?id=' + data,
    method: 'get'
  })
}

//根据分类ID查询所有产品
export function productTypeIdListAll(data) {
  return request({
    url: '/product/selectList?productTypeId=' + data,
    method: 'get'
  })
}










//---------------------------------------------------




//查询所有产品列表
export function productList() {
  return request({
    url: '/product/selectList',
    method: 'get'
  })
}

//查询所有产品和产品分类
export function productListAll() {
  return request({
    url: '/product/selectAllProduct',
    method: 'get'
  })
}