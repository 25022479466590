import React, { Component } from 'react'
import "./brief.scss"
export default class Build extends Component {
  render() {
    return (
      <div className='brief'>
        <div className="banner">
          <div className="pic">
            <img src={require("../../static/images/banner004.png")} alt="" />
          </div>
        </div>
        <div className="row-a1">
          <div className="title">
            企业简介
          </div>
          <div className="con wrap">
            <div className="desc">
              <p>
                上海鲲程电子科技有限公司，成立于2014年，总部坐落于上海市自贸区，下辖5家公司，拥有一个研发中心和两处生产基地。是立足自主技术，集研发、生产、销售和服务为一体的国家高新技术企业、上海市“专精特新”企业。
              </p>
              <p>
                以“让民爆更安全、高效”为使命，致力于成为世界一流的电子雷管核心技术和产品供应商。
                坚持以客户需求为导向，匠心打造绿色智控高质量电子雷管产品体系。公司核心团队深耕行业10余年，积累了大量药剂、物联网、电子信息等相关行业技术经验，拥有完整的电子雷管技术和产品，主要包括芯片、检测设备、起爆系统、安全管理系统、智能爆破软件、药剂优化技术等,产品广泛应用于基建拆除、开采、烟花、消防、人工降雨等领域。
              </p>
              <p>
                积极凝聚行业智慧和力量，深度布局“产学研用”融合体系。先后与中科院上海工研所、中北大学、北京理工大学、南京理工大学等多所国家重点高校共建实验室、研发中心、工作站等。同时与民爆行业国企、大型投资集团等创建智能制造企业。
              </p>
              <p>
                在各级政府、相关部门支持和鲲程精诚团结、协同共进、务实创新精神的指引下，公司在技术创新、       产品优化和市场准入方面取得了重大突破，赢得了众多客户和供应厂商的信任和支持。
                公司始终以科技为导向，不断攻坚克难、攀登一个个技术高峰，践行以智慧、绿色、安全的发展理念来推动民爆行业安全、高质量发展。
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
