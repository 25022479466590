import './App.css';

import './static/css/public.css';
import './static/css/common.css';
import './static/css/media.css';

import "animate.css"
import { Route, Routes } from 'react-router-dom'
import routes from "./router/index"

import React, { Component } from 'react';
import Loading from "./components/Loading"
import { Suspense } from "react";
export default class App extends Component {
  render() {
    return (
      <Suspense fallback={<Loading></Loading>}>
        <Routes>
          {
            routes.map((item, index) => {
              return (
                <Route path={item.path} exact={item.exact} element={item.component} key={"parent" + index}>
                  {
                    item.children ? item.children.map((it, id) => {
                      return (
                        <Route path={it.path} exact={it.exact} element={it.component} key={"child" + id}>
                        </Route>
                      )
                    }) : null
                  }
                </Route>
              )
            })
          }
        </Routes>
      </Suspense>
    )
  }
}
