import React, { Component } from 'react'

import "./index.scss"
export default class index extends Component {
  render() {
    return (
      <div className='loading'>
        <div className="logo">
          <img src={require('../../static/images/logo-icon.png')} alt="" />
        </div>
      </div>
    )
  }
}
