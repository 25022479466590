import React, { Component } from 'react';

import "./recruit.scss"

export default class recruit extends Component {
  render() {
    return (
      <div className='recruit'>
        <div className="row-a1">
          <div className="title">
            <div className="tit">
              招贤纳士
            </div>
            <div className="txt">
              RECRUITMENT
            </div>
          </div>
          <div className="con back">
            <div className="wrap">
              <div className="con-a1">
                <div className="pic">
                  <img src={require("../../static/images/pic056.png")} alt="" />
                </div>
                <div className="text">
                  <div className="tit">
                    鲲程为您提供的不止是一份工作
                  </div>
                  <div className="desc">
                    我们不仅提供富有竞争力的薪酬 <br />
                    在这里，你将沉浸于一个国际化的交流社区 <br />
                    在这里，你将成为具有国际视野和竞争力的世界公民 <br />
                    在这里，你将真实地拥抱创新 参与探索和重塑。
                  </div>
                  <div className="btn">
                    加入鲲程
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="con wrap">
            <div className="tit">
              我们将员工视为公司重要的财富，旨在吸引、聘用、激励和留住资质超群的优秀人才，携手鲲程共同成长与发展。
            </div>
            <div className="list">

              <div className="li">
                <div className="text">
                  <div className="tit">
                    福利体系
                  </div>
                </div>
                <div className="shade">
                  <ol className='list'>
                    <li>
                      社保公积金
                    </li>
                    <li>
                      商业保险
                    </li>
                    <li>
                      节假日福利
                    </li>
                    <li>
                      出差补助
                    </li>
                  </ol>
                </div>
              </div>

              <div className="li">
                <div className="text">
                  <div className="tit">
                    激励体系
                  </div>
                </div>
                <div className="shade">
                  <ol className='list'>
                    <li>
                      项目奖金
                    </li>
                    <li>
                      年终奖
                    </li>
                    <li>
                      绩效奖金
                    </li>
                  </ol>
                </div>
              </div>

              <div className="li">
                <div className="text">
                  <div className="tit">
                    丰富的活动
                  </div>
                </div>
                <div className="shade">
                  <ol className='list'>
                    <li>
                      员工生日会
                    </li>
                    <li>
                      节假日活动
                    </li>
                    <li>
                      专题活动
                    </li>
                    <li>
                      团建活动
                    </li>
                  </ol>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}
