import React, { Component } from "react";
import "./flair.scss";
import { useNavigate, useLocation } from "react-router-dom";

import { getWebsiteInfo } from "../../api/common.js";

import Swiper from "swiper/js/swiper.js"; // 引入js
import "swiper/css/swiper.min.css"; // 引入样式

class Index extends Component {
  componentDidMount() {
    this.getList();
  }
  state = {
    mySwiperCona1: null,
    mySwiperCona2: null,
    dataList: [{ image: [] }, { image: [] }],
  };
  goPage(path) {
    this.props.navigation(path);
  }
  getList() {
    getWebsiteInfo(3).then((res) => {
      console.log(res.data);
      this.setState({
        dataList: res.data,
      });
      setTimeout(() => {
        const mySwiperCona1 = new Swiper(".swiper-container-con-a1", {
          loop: true,
          navigation: {
            nextEl: ".swiper-button-next-con-a1",
            prevEl: ".swiper-button-prev-con-a1",
          },
        });
        const mySwiperCona2 = new Swiper(".swiper-container-con-a2", {
          loop: true,
          effect: "fade",
        });
        this.setState({
          mySwiperCona1,
          mySwiperCona2,
        });
      }, 200);
    });
  }

  render() {
    const { dataList } = this.state;
    return (
      <div className="flair">
        <div className="row-a1">
          <div className="title">
            <div className="tit">荣誉资质</div>
            <div className="txt">HONOR INTELLIGENCE</div>
          </div>
          <div className="con-a1 wrap">
            <div className="left">
              <div className="text">
                <div className="tit">{dataList[0].title}</div>
                <div className="desc">{dataList[0].description}</div>
              </div>
            </div>
            <div className="right">
              <div className="swiper-container-con-a1">
                <div className="swiper-wrapper">
                  {dataList[0].image.map((item, index) => {
                    return (
                      <div className="swiper-slide" key={index}>
                        <div className="item">
                          <img src={item} alt="" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="swiper-button-prev-con-a1">
                <img src={require("../../static/images/icon001.png")} alt="" />
              </div>
              <div className="swiper-button-next-con-a1">
                <img src={require("../../static/images/icon001.png")} alt="" />
              </div>
            </div>
          </div>
          <div className="con-a2 wrap">
            <div className="left">
              <div className="swiper-container-con-a2">
                <div className="swiper-wrapper">
                  {dataList[1].image.map((item, index) => {
                    return (
                      <div className="swiper-slide" key={index}>
                        <div className="pic-box">
                          <div className="pic">
                            <img src={item} alt="" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="right">
              <div className="text">
                <div className="tit">{dataList[1].title}</div>
                <div className="desc">{dataList[1].description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function About() {
  const navigation = useNavigate();
  const location = useLocation();
  return <Index navigation={navigation} location={location} />;
}
