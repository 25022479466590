import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";

import "./productuse.scss";

class Index extends Component {
  render() {
    return (
      <div className='productuse'>
        <div className="row-a1">
          <div className="title">
            <div className="tit">
              产品使用说明
            </div>
            <div className="txt">
              PRODUCT INSTRUCTIONS
            </div>
          </div>
          <div className="con wrap">
            <div className="nav">
              <div className="tit">
                产品说明书
              </div>
              <div className="btn" onClick={() => { this.props.navigation("/download/illustrate") }}>
                <div className="icon">
                  <img src={require("../../static/images/icon023.png")} alt="" />
                </div>
                <div className="txt">
                  返回
                </div>
              </div>
            </div>
            <div className="box">
              <div className="left">
                <div className="tit">
                  请发邮件联系我们
                </div>
                <div className="desc row">
                  为了更好的为您服务，请扫右侧二维码联系我们<br />
                  备注来意，如：产品说明书下载申请<br />
                  鲲程电子将竭诚为您服务，感谢您的支持
                </div>
                <div className="desc col">
                  为了更好的为您服务，请扫下方二维码联系我们<br />
                  备注来意，如：产品说明书下载申请<br />
                  鲲程电子将竭诚为您服务，感谢您的支持
                </div>
              </div>
              <div className="right">
                <div className="tit">
                  联系下方微信申请下载
                </div>
                <div className="wx-code">
                  <img src={require("../../static/images/wx-code.png")} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default function Productuse() {
  const navigation = useNavigate();
  return <Index navigation={navigation} />;
}