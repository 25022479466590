import React from 'react';

// import Index from "../views/index";
import Home from "../views/home";
import Error from "../views/error/error";

/**
 * 关于我们
 * -企业简介
 * -企业发展历程
 * -企业架构
 * -荣誉资质
 * -战略合作
 * -核心竞争力
 * -可持续发展
 * -投资者关系
 */
import Brief from "../views/about/brief";
import History from "../views/about/history";
import Build from "../views/about/build";
import Flair from "../views/about/flair";
import Cooperate from "../views/about/cooperate";
import Core from "../views/about/core";
import Develop from "../views/about/develop";
import Invest from "../views/about/invest";

/**
 * 产品中心与解决方案
 * -产品中心
 * -产品分类-产品中心
 * -产品详情
 * -经典案例
 * -拓展领域
 */
import ProductCenter from "../views/product/productCenter";
import ProductType from "../views/product/productType";
import ProductInfo from "../views/product/productInfo";
import Case from "../views/product/case";
import Area from "../views/product/area";

/**
 * 新闻资讯
 * 
 * -行业动态
 * -企业动态
 */
import Industry from "../views/news/industry";
import Company from "../views/news/company";

/**
 * 加入鲲程
 * 
 * -鲲程文化
 * -鲲程人才
 * -培养发展
 * -招贤纳士
 */
import Culture from "../views/join/culture";
import Genius from "../views/join/genius";
import Regard from "../views/join/regard";
import Recruit from "../views/join/recruit";


/**
 * 下载中心
 * -产品使用说明
 * --产品使用说明
 * --起爆控制器操作指引视频
 * -终端使用程序
 */
import Terminal from "../views/download/terminal";
import Illustrate from "../views/download/illustrate";
import Productuse from "../views/download/productuse";
import Productvideo from "../views/download/productvideo";

/**
 * 联系我们
 * -客户反馈
 */
import Custom from "../views/contact/custom";

import { lazy } from "react";
const Index = lazy(() => import("../views/index"));

const routers = [
  {
    path: '*',
    exact: false,
    component: <Error></Error>
  },
  {
    path: '/',
    exact: false, //是否严格匹配路由
    component: <Index></Index>,
    children: [
      {
        path: '/',
        exact: false,
        component: <Home></Home>
      },
      {
        path: '/about/brief',
        exact: false,
        component: <Brief></Brief>
      },
      {
        path: '/about/history',
        exact: false,
        component: <History></History>
      },
      {
        path: '/about/build',
        exact: false,
        component: <Build></Build>
      },
      {
        path: '/about/flair',
        exact: false,
        component: <Flair></Flair>
      },
      {
        path: '/about/cooperate',
        exact: false,
        component: <Cooperate></Cooperate>
      },
      {
        path: '/about/core',
        exact: false,
        component: <Core></Core>
      },
      {
        path: '/about/develop',
        exact: false,
        component: <Develop></Develop>
      },
      {
        path: '/about/invest',
        exact: false,
        component: <Invest></Invest>
      },
      {
        path: '/product/center',
        exact: false,
        component: <ProductCenter></ProductCenter>
      },
      {
        path: '/product/type',
        exact: false,
        component: <ProductType></ProductType>
      },
      {
        path: '/product/info',
        exact: false,
        component: <ProductInfo></ProductInfo>
      },
      {
        path: '/product/case',
        exact: false,
        component: <Case></Case>
      },
      {
        path: '/product/area',
        exact: false,
        component: <Area></Area>
      },
      {
        path: '/news/industry',
        exact: false,
        component: <Industry></Industry>
      },
      {
        path: '/news/company',
        exact: false,
        component: <Company></Company>
      },
      {
        path: '/join/culture',
        exact: false,
        component: <Culture></Culture>
      },
      {
        path: '/join/genius',
        exact: false,
        component: <Genius></Genius>
      },
      {
        path: '/join/regard',
        exact: false,
        component: <Regard></Regard>
      },
      {
        path: '/join/recruit',
        exact: false,
        component: <Recruit></Recruit>
      },
      {
        path: '/download/terminal',
        exact: false,
        component: <Terminal></Terminal>
      },
      {
        path: '/download/illustrate',
        exact: false,
        component: <Illustrate></Illustrate>
      },
      {
        path: '/download/use',
        exact: false,
        component: <Productuse></Productuse>
      },
      {
        path: '/download/video',
        exact: false,
        component: <Productvideo></Productvideo>
      },
      {
        path: '/contact/custom',
        exact: false,
        component: <Custom></Custom>
      }
    ]
  }
];
export default routers;