/*
 * @Description:
 * @version:
 * @Author: 香菜
 * @Date: 2024-03-31 12:53:54
 * @LastEditors:
 * @LastEditTime: 2024-03-31 15:37:08
 */
import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import "./productCenter.scss";
import { getProductTypeList } from "../../api/product";
class Index extends Component {
  state = {
    productTypeList: [],
  };
  componentDidMount() {
    this.getList();
  }
  goPage(path) {
    this.props.navigation(path);
  }
  getList() {
    getProductTypeList().then((res) => {
      this.setState({
        productTypeList: res.data.records,
      });
    });
  }
  render() {
    const { productTypeList } = this.state;
    return (
      <div className="product-center">
        <div className="row-a1">
          <div className="title">
            <div className="tit">产品中心</div>
            <div className="txt">PRODUCT CENTER</div>
          </div>
          <div className="con wrap">
            <div className="list">
              {productTypeList.map((item, index) => {
                return (
                  <div
                    className="li"
                    key={index}
                    onClick={() => {
                      this.goPage("/product/type?productTypeId=" + item.id);
                    }}
                  >
                    <div className="pic">
                      <img src={item.imageUrl} alt="" />
                    </div>
                    <div className="text">
                      <div className="tit">{item.name}</div>
                      <div className="desc ellipsis_5">{item.description}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function ProductCenter() {
  const navigation = useNavigate();
  return <Index navigation={navigation} />;
}
