import React, { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./productType.scss";
import { productTypeSelectOne, productTypeIdListAll } from "../../api/product";
import { getRequest } from "../../utils/index";
class Index extends Component {
  state = {
    productTypeInfo: {},
    productTypeList: [],
  };
  componentDidMount() {
    let params = getRequest(this.props.location.search);
    for (const key in params) {
      if (key == "productTypeId") {
        this.getList(params[key]);
      }
    }
  }
  getList(id) {
    productTypeSelectOne(id).then((res) => {
      if (res.status == "success") {
        this.setState({
          productTypeInfo: res.data,
        });
      } else {
        this.props.navigation("/product/center");
      }
    });
    productTypeIdListAll(id).then((res) => {
      if (res.status == "success") {
        this.setState({
          productTypeList: res.data,
        });
      } else {
        this.props.navigation("/product/center");
      }
    });
  }
  goPage(path) {
    this.props.navigation(path);
  }
  render() {
    const { productTypeInfo, productTypeList } = this.state;
    return (
      <div className="product-type">
        <div className="row-a1">
          <div className="title">
            <div className="tit">产品中心</div>
            <div className="txt">PRODUCT CENTER</div>
          </div>
          <div className="con wrap">
            <div className="nav">
              <div className="top">
                <div className="tit">{productTypeInfo.name}</div>
                <div className="desc">{productTypeInfo.description}</div>
              </div>
              <div
                className="btn"
                onClick={() => {
                  this.props.navigation("/product/center");
                }}
              >
                <div className="icon">
                  <img
                    src={require("../../static/images/icon023.png")}
                    alt=""
                  />
                </div>
                <div className="txt">返回</div>
              </div>
            </div>
            <div className="list">
              {productTypeList.map((item, index) => {
                return item.showType == 0 ? (
                  <div
                    className="li"
                    key={index}
                    onClick={() => {
                      this.goPage("/product/info?productId=" + item.id+'&productTypeId='+item.productTypeId);
                    }}
                  >
                    <div className="pic">
                      <img src={item.imageUrl} alt="" />
                    </div>
                    <div className="text">
                      <div className="tit">{item.name}</div>
                      <div className="desc ellipsis_5">{item.description}</div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="lii"
                    key={index}
                    onClick={() => {
                      this.goPage("/product/info?productId=" + item.id+'&productTypeId='+item.productTypeId);
                    }}
                  >
                    <div className="pic">
                      <img src={item.imageUrl} alt="" />
                    </div>
                    <div className="text">
                      <div className="tit">{item.name}</div>
                      <div className="desc ellipsis_5">{item.description}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function ProductType() {
  const navigation = useNavigate();
  const location = useLocation();
  return <Index navigation={navigation} location={location} />;
}
