import request from "../utils/request";

/**
 * @param {公共查询网站管理接口}
 * @returns 
 * 
 *{
    label:"鲲程人才",
    value:1
  },
  {
    label:"培养发展",
    value:2
  },
  {
    label:"荣誉资质",
    value:3
  },
  {
    label:"战略合作",
    value:4
  }
 */
export function getWebsiteInfo(data) {
  return request({
    url: "/website/selectWebsiteByType?type=" + data,
    method: "get",
  });
}
