import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";

import "./productvideo.scss";

class Index extends Component {
  video1 = React.createRef(null);
  video2 = React.createRef(null);
  state = {
    player: 0
  }
  player = (current) => {
    switch (current) {
      case 1:
        this.video1.current.play();
        this.video2.current.pause();
        this.setState({
          player: current
        })
        break;
      case 2:
        this.video1.current.pause();
        this.video2.current.play();
        this.setState({
          player: current
        })
        break;
      default:
        break;
    }
  }
  render() {
    return (
      <div className='productvideo'>
        <div className="row-a1">
          <div className="title">
            <div className="tit">
              产品使用说明
            </div>
            <div className="txt">
              PRODUCT INSTRUCTIONS
            </div>
          </div>
          <div className="con wrap">
            <div className="nav">
              <div className="tit">
                起爆控制器操作指引视频
              </div>
              <div className="btn" onClick={() => { this.props.navigation("/download/illustrate") }}>
                <div className="icon">
                  <img src={require("../../static/images/icon023.png")} alt="" />
                </div>
                <div className="txt">
                  返回
                </div>
              </div>
            </div>
            <div className="list">

              <div className="li">
                <div className={this.state.player == 1 ? 'video play' : 'video'}>
                  <video ref={this.video1} src="https://shkc2023-1314903162.cos.ap-shanghai.myqcloud.com/train_video/%E5%9F%B9%E8%AE%AD%E8%A7%86%E9%A2%91%EF%BC%88%E5%8A%A0%E5%85%A5%E9%A2%84%E8%AE%BE%E5%BB%B6%E6%9C%9F%E5%90%8E%EF%BC%89.mp4" controls>
                  </video>
                  <div className="icon" onClick={() => { this.player(1) }}>
                    <img src={require("../../static/images/icon033.png")} alt="" />
                  </div>
                  <div className='pic'>
                    <img src={require("../../static/images/pic057.png")} alt="" />
                  </div>
                </div>
                <div className="tit ellipsis_3">
                  橙色起爆器操作指引视频
                  （加入预设延期后）
                </div>
                <div className="btn">
                  点击下载
                </div>
              </div>

              <div className="li">
                <div className={this.state.player == 2 ? 'video play' : 'video'}>
                  <video ref={this.video2} src="https://shkc2023-1314903162.cos.ap-shanghai.myqcloud.com/train_video/%E5%9F%B9%E8%AE%AD%E8%A7%86%E9%A2%91.mp4" controls>
                  </video>
                  <div className="icon" onClick={() => { this.player(2) }}>
                    <img src={require("../../static/images/icon033.png")} alt="" />
                  </div>
                  <div className='pic'>
                    <img src={require("../../static/images/pic057.png")} alt="" />
                  </div>
                </div>
                <div className="tit ellipsis_3">
                  橙色起爆器操作指引视频
                </div>
                <div className="btn">
                  点击下载
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default function Productuse() {
  const navigation = useNavigate();
  return <Index navigation={navigation} />;
}