import React, { Component } from 'react';
import { message } from 'antd';

import "./terminal.scss";
export default class extends Component {

  componentDidMount() {

  }
  copy() {
    navigator.clipboard.writeText("kunchengdianzi@shkcdz.com");
    message.success('复制成功!');
  }
  render() {
    return (
      <div className='terminal'>
        <div className="row-a1">
          <div className="title">
            <div className="tit">
              终端使用程序
            </div>
            <div className="txt">
              TERMINAL USE
            </div>
          </div>
          <div className="con wrap">
            <div className="left">
              <div className="tit">
                请发邮件联系我们
              </div>
              <div className="desc row">
                请向右侧邮箱号中发送申请下载应用邮件，模板如下<br />
                邮件名称：终端使用程序下载申请<br />
                邮件内容：姓名+联系电话+申请描述
              </div>
              <div className="desc col">
                请向下方邮箱号中发送申请下载应用邮件，模板如下<br />
                邮件名称：终端使用程序下载申请<br />
                邮件内容：姓名+联系电话+申请描述
              </div>
            </div>
            <div className="right">
              <div className="tit">
                联系下方邮箱申请下载
              </div>
              <div className="email">
                <div className="icon">
                  <img src={require("../../static/images/icon032.png")} alt="" />
                </div>
                <div className="txt">
                  kunchengdianzi@shkcdz.com
                </div>
              </div>
              <div className="btn" onClick={this.copy}>
                点击复制邮箱号
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
