import React, { Component } from "react";
import "./core.scss";
export default class core extends Component {
  render() {
    return (
      <div className="core">
        <div className="row-a1">
          <div className="title">
            <div className="tit">核心竞争力</div>
            <div className="txt">CORE COMPETENCE</div>
          </div>
          <div className="banner">
            <img src={require("../../static/images/banner005.png")} alt="" />
          </div>
          <div className="con-a1">
            <div className="wrap">
              <div className="list">
                <div className="li">
                  <div className="set-type">
                    <div className="icon">
                      <img
                        src={require("../../static/images/icon022.png")}
                        alt=""
                      />
                    </div>

                    <div className="txt">
                      自有企业研发中心，芯片集成度高，性价比优
                    </div>
                  </div>
                  <div className="desc">
                    <div className="item">
                      <span className="sub-title">
                        <img
                          src={require("../../static/images/icon035.png")}
                          className="sub-icon"
                        ></img>
                        <span className="sub-title">高效率：</span>
                      </span>
                      <span className="sub-desc">
                        企业自有的研发中心，可以高效的使客户的需求落地转化为产品
                      </span>
                    </div>
                    <div className="item">
                      <span className="sub-title">
                        <img
                          src={require("../../static/images/icon035.png")}
                          className="sub-icon"
                        ></img>
                        <span className="sub-title">可靠性：</span>
                      </span>
                      <span className="sub-desc">
                        自主研发系统可以依据客户的实际需求
                        制定个性化解决方案，具有更高的可靠性 与稳定性
                      </span>
                    </div>
                    <div className="item">
                      <span className="sub-title">
                        <img
                          src={require("../../static/images/icon035.png")}
                          className="sub-icon"
                        ></img>
                        <span className="sub-title">低成本：</span>
                      </span>
                      <span className="sub-desc">
                        自主研发的芯片具有较高的性价比优势
                      </span>
                    </div>
                  </div>
                </div>

                <div className="li">
                  <div className="set-type">
                    <div className="icon">
                      <img
                        src={require("../../static/images/icon022.png")}
                        alt=""
                      />
                    </div>
                    <div className="txt">
                      合作客户量国内排名领先，区域市场覆盖率 稳居前列
                    </div>
                  </div>
                  <div className="desc">
                    <div className="item">
                      <span className="sub-title">
                        <img
                          src={require("../../static/images/icon035.png")}
                          className="sub-icon"
                        ></img>
                        <span className="sub-title">团队专业：</span>
                      </span>
                      <span className="sub-desc">
                        高效的售前沟通，迅速在公司内部转化落地，研发+技术的无缝配合，专业的售后服务，使鲲程与客户始终处于同步状态
                      </span>
                    </div>
                    <div className="item">
                      <span className="sub-title">
                        <img
                          src={require("../../static/images/icon035.png")}
                          className="sub-icon"
                        ></img>
                        <span className="sub-title">协同推进：</span>
                      </span>
                      <span className="sub-desc">
                        鲲程以站在客户的立场上思考，与客户同呼吸共命运，理解客户的真正需求，及时有效的服力，为客户真正解决问题做为服务理念，市场口碑良好
                      </span>
                    </div>
                  </div>
                </div>
                <div className="li">
                  <div className="set-type">
                    <div className="icon">
                      <img
                        src={require("../../static/images/icon022.png")}
                        alt=""
                      />
                    </div>
                    <div className="txt">
                      实现电子雷管全产业链布局，延时控制模组全工序自主生产，研发制造水平行业领先
                    </div>
                  </div>
                  <div className="desc">
                    <div className="item">
                      <span className="sub-title">
                        <img
                          src={require("../../static/images/icon035.png")}
                          className="sub-icon"
                        ></img>
                        <span className="sub-title">降成本：</span>
                      </span>
                      <span className="sub-desc">
                        生产工序全产业链发展可实现生产、销售、物流环节的协同合作，减少中间环节，降低生产成本
                      </span>
                    </div>
                    <div className="item">
                      <span className="sub-title">
                        <img
                          src={require("../../static/images/icon035.png")}
                          className="sub-icon"
                        ></img>
                        <span className="sub-title">高质量：</span>
                      </span>
                      <span className="sub-desc">
                        从原材料采购到产品销售全流程管控，保证生产质量，提高产品的附加值
                      </span>
                    </div>
                    <div className="item">
                      <span className="sub-title">
                        <img
                          src={require("../../static/images/icon035.png")}
                          className="sub-icon"
                        ></img>
                        <span className="sub-title">保交货：</span>
                      </span>
                      <span className="sub-desc">
                        通过计划拉动制造工序上下游，确保产品的交付时效
                      </span>
                    </div>
                  </div>
                </div>
                <div className="li">
                  <div className="set-type">
                    <div className="icon">
                      <img
                        src={require("../../static/images/icon022.png")}
                        alt=""
                      />
                    </div>
                    <div className="txt">
                      10年+电子雷管从业经验，物联网管理概念首家提出者，行业标准制定参与者
                    </div>
                  </div>
                  <div className="desc">
                    <div className="item">
                      <span className="sub-title">
                        <img
                          src={require("../../static/images/icon035.png")}
                          className="sub-icon"
                        ></img>
                        <span className="sub-title">实力彰显：</span>
                      </span>
                      <span className="sub-desc">
                        深耕行业10+年，积极丰富的行业经验，参与行业标准的制定，是国家对鲲程专业能力和技术实力的肯定
                      </span>
                    </div>
                    <div className="item">
                      <span className="sub-title">
                        <img
                          src={require("../../static/images/icon035.png")}
                          className="sub-icon"
                        ></img>
                        <span className="sub-title">技术创新：</span>
                      </span>
                      <span className="sub-desc">
                        不断探索和研究行业新技术和方法，推动企业技术创新
                      </span>
                    </div>
                    <div className="item">
                      <span className="sub-title">
                        <img
                          src={require("../../static/images/icon035.png")}
                          className="sub-icon"
                        ></img>
                        <span className="sub-title">助力发展：</span>
                      </span>
                      <span className="sub-desc">
                        参与标准定制，推动行业发展，使行业更加规范化、标准化和健康。
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="con-a2 wrap">
            <div className="texts">
              <div className="text">
                <div className="txts">
                  <div className="font">完整</div>
                  <div className="txt">的产品体系</div>
                  <div className="font-w">快速</div>
                  <div className="txt">优化及迭代能力</div>
                </div>
                <div className="line">
                  <div className="one"></div>
                  <div className="two"></div>
                  <div className="three"></div>
                </div>
              </div>
              <div className="text">
                <div className="txts">
                  <div className="font-w">专业</div>
                  <div className="txt">的技术服务团队</div>
                  <div className="font">全链条</div>
                  <div className="txt">质量管控</div>
                </div>
                <div className="line">
                  <div className="one"></div>
                  <div className="two"></div>
                  <div className="three"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
