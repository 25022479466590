import React, { Component } from 'react';
import { getWebsiteInfo } from "../../api/common.js"

import "./genius.scss";

export default class extends Component {
  state = {
    list: [{ image: "" }, { image: "" }, { image: "" }]
  }
  componentDidMount() {
    this.getList();
  }
  getList() {
    getWebsiteInfo(1).then(res => {
      this.setState({
        list: res.data
      })
    })
  }
  render() {
    const { list } = this.state;
    return (
      <div className='genius'>
        <div className="row-a1">
          <div className="title">
            <div className="tit">
              鲲程人才
            </div>
            <div className="txt">
              KUNCHENG TALENT
            </div>
          </div>
          <div className="con wrap">
            <div className="con-a1">
              <div className="pic">
                <img src={list[0].image[0]} alt="" />
              </div>
              <div className="desc">
                <div className="tit">
                  {list[0].title}
                </div>
                <p>
                  {list[0].description}
                </p>
              </div>
            </div>
            <div className="con-a2">
              <div className="desc">
                <div className="tit">
                  {list[1].title}
                </div>
                <p>
                  {list[1].description}
                </p>
              </div>
              <div className="pic">
                <img src={list[1].image[0]} alt="" />
              </div>
            </div>
            <div className="con-a1">
              <div className="pic">
                <img src={list[2].image[0]} alt="" />
              </div>
              <div className="desc">
                <div className="tit">
                  {list[2].title}
                </div>
                <p>
                  {list[2].description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
