import React, { Component } from 'react';
import "./culture.scss";
export default class culture extends Component {

  render() {
    return (
      <div className='culture'>
        <div className="row-a1">
          <div className="title">
            <div className="tit">
              鲲程文化
            </div>
            <div className="txt">
              KUNCHENG CULTURE
            </div>
          </div>
          <div className="con wrap">
            <div className="list">
              <div className="li">
                <div className="con-a1">
                  <div className="icons icon-box1">
                    <div className="icon1">
                      <img src={require("../../static/images/icon024.png")} alt="" />
                    </div>
                    <div className="icon2">
                      <img src={require("../../static/images/icon025.png")} alt="" />
                    </div>
                    <div className="icon3">
                      <img src={require("../../static/images/icon024.png")} alt="" />
                    </div>
                  </div>
                  <div className="text">
                    <div className="tit">
                      愿景
                    </div>
                    <div className="txt">
                      Aspiration
                    </div>
                  </div>
                </div>

                <div className="con-a2">
                  <div className="anima">

                  </div>
                </div>
                <div className="con-a3">
                  做世界一流的<br />
                  电子雷管核心技术<br />
                  和产品供应商
                </div>
              </div>
              <div className="li">
                <div className="con-a1">
                  <div className="icons icon-box2">
                    <div className="icon1">
                      <img src={require("../../static/images/icon024.png")} alt="" />
                    </div>
                    <div className="icon2">
                      <img src={require("../../static/images/icon031.png")} alt="" />
                    </div>
                    <div className="icon3">
                      <img src={require("../../static/images/icon026.png")} alt="" />
                    </div>
                  </div>
                  <div className="text">
                    <div className="tit">
                      使命
                    </div>
                    <div className="txt">
                      Minssion
                    </div>
                  </div>
                </div>
                <div className="con-a2">
                  <div className="anima">

                  </div>
                </div>
                <div className="con-a3">
                  让民爆<br />
                  更安全<br />
                  更高效
                </div>
              </div>
              <div className="li">
                <div className="con-a1">
                  <div className="icons icon-box3">
                    <div className="icon1">
                      <img src={require("../../static/images/icon024.png")} alt="" />
                    </div>
                    <div className="icon2">
                      <img src={require("../../static/images/icon030.png")} alt="" />
                    </div>
                    <div className="icon3">
                      <img src={require("../../static/images/icon027.png")} alt="" />
                    </div>
                  </div>
                  <div className="text">
                    <div className="tit">
                      经营理念
                    </div>
                    <div className="txt">
                      Operation Principle
                    </div>
                  </div>
                </div>
                <div className="con-a2">
                  <div className="anima">

                  </div>
                </div>
                <div className="con-a3">
                  深入融合，合作共赢<br />
                  以人为本，务实创新
                </div>
              </div>
              <div className="li">
                <div className="con-a1">
                  <div className="icons icon-box4">
                    <div className="icon1">
                      <img src={require("../../static/images/icon024.png")} alt="" />
                    </div>
                    <div className="icon2">
                      <img src={require("../../static/images/icon031.png")} alt="" />
                    </div>
                    <div className="icon3">
                      <img src={require("../../static/images/icon028.png")} alt="" />
                    </div>
                  </div>
                  <div className="text">
                    <div className="tit">
                      服务理念
                    </div>
                    <div className="txt">
                      Service Principle
                    </div>
                  </div>
                </div>
                <div className="con-a2">
                  <div className="anima">

                  </div>
                </div>
                <div className="con-a3">
                  站在客户的立场上思考<br />
                  与客户同呼吸共命运<br />
                  理解客户的真正需求<br />
                  及时有效的服务<br />
                  为客户真正解决问题
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
